import React, { useState, useEffect } from "react";
import {
  Badge,
  Avatar,
  Button,
  Table,
  Modal,
  Popconfirm,
  Select,
  Switch,
  Typography,
  message,
  Tooltip
} from "antd";
import { StopOutlined, InfoCircleOutlined } from "@ant-design/icons";
import CommonApis from "../api/CommonApis";

export default function UserData(props) {
  const { Option } = Select;
  const { Title } = Typography;
  const [modalVisible, setModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [showProfileFuns, setShowProfileFuncs] = useState(false);
  const [postStatus, setPostStatus] = useState(true);
  const [postObjective, setPostObjective] = useState("");
  const [currentPost, setCurrentPost] = useState({});


  const buildUpdateModal = (record) => {
    setCurrentPost(record)
    setPostObjective(record.objective);
    if (
      record.objective === "friendRequest" ||
      record.objective === "followRequest"
    ) {
      setShowProfileFuncs(true);
    } else setShowProfileFuncs(false);

    setPostStatus(record.live ? true : false);

    setModalVisible(true);
  };
  
  const handleModalChange = () => {
    let post = {...currentPost};
    post.live = postStatus;
    post.objective = postObjective;
    console.log(post)
    let thisProps = { ...props };
    setModalLoading(true);
    CommonApis.updateUserPost(post)
    .then(res => {
      thisProps.handleParentFun();
      setModalLoading(false);
      setModalVisible(false)
    })
    .catch(err => {
        message.error('Error updating post!')
        setModalLoading(false);
    })
    
  };

  const onPostStatusChange = (checked) => {
    setPostStatus(checked);
  };

  const handleDelete = (id) => {
    let thisProps = { ...props };
    //  thisProps.handleParentFun();

    CommonApis.deletePost(id).then((res) => {
      thisProps.handleParentFun();
    });
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleModalObjectiveChange = (value) => {
    setPostObjective(value);
  };

  const columns = [
    {
      title: "Objective",
      dataIndex: "objective",
      key: "objective",
      width: "2%",
      render: (objective) => (
        <Avatar
          size={48}
          src={process.env.PUBLIC_URL + "/images/" + objective + ".png"}
          alt={objective}
        />
      ),
    },
    {
      title: "Post Url",
      dataIndex: "link",
      key: "link",
      render: (link) => (
        <a href={link} target="_blank">
          {link}
        </a>
      ),
    },
    {
      title: "Objective reach",
      key: "max",
      render: (text, record, index) => (
        <span>{record.objectiveCount + "/" + record.maxCount}</span>
      ),
    },
    {
      title: "Status",
      dataIndex: "live",
      key: "live",
      render: (status) =>
        status ? (
          <Badge color={"green"} text="Active" />
        ) : (
          <Badge color={"red"} text="Inactive" />
        ),
    },
    {
      title: "User",
      dataIndex: "userId",
      key: "userId",
      render: (userId, row) => (
          <a href={`/user/details/${row.userId}/`}>{userId}</a>
      ),
    },
    {
      title: "Date Published",
      dataIndex: "createdAt",
      key: "createdat",
    },
    {
      title: "Actions",
      key: "operation",
      render: (record, rowIndex) => (
        <div>
          <Button type="dashed" onClick={() => buildUpdateModal(record)}>
            Update Post
          </Button>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record.id)}
          >
            <a style={{ marginLeft: "10px", color: "red" }}>Delete</a>
          </Popconfirm>
          <Tooltip title="Block this user">
        </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={props.data} rowKey="userId" />

      <Modal
        title="Update User Post"
        visible={modalVisible}
        onOk={handleModalChange}
        confirmLoading={modalLoading}
        onCancel={handleCancel}
      >
        <p>Post Objective:</p>


        {!showProfileFuns ? (
          <div>
          {currentPost.platform === "Facebook" ? (<Select
            defaultValue="Like"
            style={{ width: 220 }}
            value={postObjective}
            onChange={handleModalObjectiveChange}
          >
            <Option value="Like">Like</Option>
            <Option value="Love">Love</Option>
            <Option value="Haha">Haha</Option>
            <Option value="Wow">Wow</Option>
            <Option value="Sad">Sad</Option>
            <Option value="Angry">Angry</Option>
          </Select>) : (<div></div>)}

          {currentPost.platform === "YouTube" ? (<Select
            defaultValue="YoutubeLike"
            style={{ width: 220 }}
            value={postObjective}
            onChange={handleModalObjectiveChange}
          >
            <Option value="YoutubeLike">YoutubeLike</Option>
            <Option value="YoutubeSubsribe">YoutubeSubsribe</Option>
          </Select>) : (<div></div>)}
          <br />
          <br />
          <br />
        </div>
        ) : (
          <div>
            <Select
              defaultValue="friendRequest"
              style={{ width: 220 }}
              value={postObjective}
              onChange={handleModalObjectiveChange}
            >
              <Option value="friendRequest">Add Friend</Option>
              <Option value="followRequest">Follow Me</Option>
            </Select>

            <br />
            <br />
            <br />
          </div>
        )}
        <span>
          <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} /> *When post
          status is on 'Stopped', the post won't get any new Likes or reactions.
        </span>
        <br />
        <Switch
          checkedChildren="Live"
          unCheckedChildren="Stopped"
          defaultChecked
          checked={postStatus}
          onChange={onPostStatusChange}
        />{" "}
        <label style={{ marginLeft: "10px" }}> Switch Post Status </label>
      </Modal>
    </div>
  );
}
