import React, {useState, useEffect} from "react";
import {Layout, Menu, Avatar, Tooltip, Button, message} from "antd";
import {
    AppstoreTwoTone,
    BarChartOutlined,
    LogoutOutlined,
    DollarTwoTone,
    CopyTwoTone,
    FireTwoTone,
    SettingOutlined, UsergroupAddOutlined,
} from "@ant-design/icons";

import Dashboard from "./Dashboard";
import SocialPasses from "./SocialPasses";
import Ads from "./Ads"
import "./styles.css";
import CommonApis from "../api/CommonApis";
import UserPosts from "../posts";
import AllLinks from "../allLinks";
import ArchivedLinks from "../archivedLinks";
import AppRelease from "../release";
import Comments from "./preferences/comments/index"
import UsersReferrals from "../users-referrals/UsersReferrals";

function Home(props) {
    /**
     * key: "1",
     email: "test@gmail.com",
     name: "John Brown",
     isCustomer: "No",
     totalPurchases: "$50",
     totalPoints: "220",
     totalActivePosts: "5",
     totalInactivePosts: "0"
     */

    const [showoDashboard, setShowDashboard] = useState(true);
    const [showoDashboardAllLinks, setShowDashboardAllLinks] = useState(false);
    const [showArchivedLinks, setShowArchivedLinks] = useState(false);
    const [showSocialPasses, setShowSocialPasses] = useState(false);
    const [showAppRelease, setShowAppRelease] = useState(false);
    const [showAds, setShowAds] = useState(false)
    const [platform, setPlatform] = useState("Facebook");
    const [showComments, setShowComments] = useState(false)
    const [showUsersReferrals, setShowUsersReferrals] = useState(false)

    const [collapsed, setCollapsed] = useState(false);
    const [adminHeader, setAdminHeader] = useState("Admin");

    const {Header, Content, Footer, Sider} = Layout;
    const {SubMenu} = Menu;

    const onMenuDashboard = () => {
        console.log("menu dashboard clicked");
        setShowSocialPasses(false);
        setShowAppRelease(false);
        setShowDashboardAllLinks(false);
        setShowDashboard(true);
        setShowArchivedLinks(false)
        setShowAds(false)
        setShowComments(false)
        setShowUsersReferrals(false);
    };

    const onUsersReferrals = () => {
        setShowSocialPasses(false);
        setShowAppRelease(false);
        setShowDashboardAllLinks(false);
        setShowDashboard(false);
        setShowArchivedLinks(false)
        setShowAds(false)
        setShowComments(false);
        setShowUsersReferrals(true);
    }

    const onMenuArchivedLinks = () => {
        console.log("menu archived clicked");
        setShowSocialPasses(false);
        setShowAppRelease(false);
        setShowDashboardAllLinks(false);
        setShowDashboard(false);
        setShowArchivedLinks(true);
        setShowAds(false)
        setShowComments(false)
        setShowUsersReferrals(false);
    }

    const onMenuPasses = () => {
        console.log("menu passes clicked");
        setShowDashboard(false);
        setShowAppRelease(false);
        setShowDashboardAllLinks(false);
        setShowArchivedLinks(false)
        setShowSocialPasses(true);
        setShowAds(false)
        setShowComments(false)
        setShowUsersReferrals(false);

    };

    const onMenuAppRelease = () => {
        setShowSocialPasses(false);
        setShowDashboard(false);
        setShowDashboardAllLinks(false);
        setShowArchivedLinks(false)
        setShowAppRelease(true);
        setShowAds(false)
        setShowComments(false)
        setShowUsersReferrals(false);
    };

    const onMenuDashboardAllLinks = (event) => {
        setPlatform(event.item.props.children[1]);
        setShowDashboardAllLinks(false);
        setShowSocialPasses(false);
        setShowDashboard(false);
        setShowAppRelease(false);
        setShowArchivedLinks(false)
        setShowDashboardAllLinks(true);
        setShowAds(false)
        setShowComments(false)
        setShowUsersReferrals(false);
    };

    const onMenuAds = () => {
        setShowDashboardAllLinks(false);
        setShowSocialPasses(false);
        setShowDashboard(false);
        setShowAppRelease(false);
        setShowArchivedLinks(false)
        setShowDashboardAllLinks(false);
        setShowAds(true);
        setShowComments(false)
        setShowUsersReferrals(false);
    };

    const onComments = () => {
        setShowDashboardAllLinks(false);
        setShowSocialPasses(false);
        setShowDashboard(false);
        setShowAppRelease(false);
        setShowArchivedLinks(false)
        setShowDashboardAllLinks(false);
        setShowAds(false);
        setShowComments(true)
        setShowUsersReferrals(false);
    }

    const logout = () => {
        CommonApis.logout();
        props.history.push("/login");
        message.success("logged out..");
    };

    const onCollapse = (collapsed) => {
        console.log(collapsed);
        setCollapsed(collapsed);
        if (collapsed) setAdminHeader("");
        else setAdminHeader("Admin");
    };

    return (
        <div>
            <Layout>
                <Sider
                    collapsible
                    collapsed={collapsed}
                    onCollapse={onCollapse}
                    style={{
                        overflow: "auto",
                        height: "100vh",
                        position: "fixed",
                        left: 0,
                    }}
                >
                    <div className="logo">
                        <table>
                            <tr>
                                <td>
                                    <Avatar
                                        style={{
                                            color: "#f56a00",
                                            backgroundColor: "#fde3cf",
                                            marginTop: 10,
                                        }}
                                    >
                                        A
                                    </Avatar>
                                </td>
                                <td>
                                    <br/>
                                    <h2 className="admin-logo-text"> {adminHeader}</h2>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={["0"]}>


                        <Menu.Item key="0" onClick={onMenuDashboard}>
                            <AppstoreTwoTone/>
                            <span className="nav-text">Manage Users</span>
                        </Menu.Item>
                        <Menu.Item key="referralUsers" onClick={onUsersReferrals}>
                            <UsergroupAddOutlined style={{color: "white"}} />
                            <span className="nav-text">Users Referrals</span>
                        </Menu.Item>


                        <SubMenu key="sub1" icon={<BarChartOutlined/>} title="Manage Links">
                            <Menu.Item key="1" onClick={onMenuDashboardAllLinks}>Facebook</Menu.Item>
                            <Menu.Item key="2" onClick={onMenuDashboardAllLinks}>YouTube</Menu.Item>
                            <Menu.Item disabled key="3">Instagram</Menu.Item>
                            <Menu.Item disabled key="4">Twitter</Menu.Item>
                        </SubMenu>

                        <Menu.Item key="5" onClick={onMenuArchivedLinks}>
                            <CopyTwoTone/>
                            <span className="nav-text">Archived Links</span>
                        </Menu.Item>

                        <Menu.Item key="6" onClick={onMenuPasses}>
                            <AppstoreTwoTone/>
                            <span className="nav-text">Social Passes</span>
                        </Menu.Item>

                        <Menu.Item key="7" onClick={onMenuAppRelease}>
                            <FireTwoTone/>
                            <span className="nav-text">Manage Releases</span>
                        </Menu.Item>


                        <Menu.Item key="8" onClick={onMenuAds}>
                            <DollarTwoTone/>
                            <span className="nav-text">Manage Ads</span>
                        </Menu.Item>

                        <SubMenu key="sub2" icon={<SettingOutlined/>} title="Preferences">
                            <Menu.Item key="9" onClick={onComments}>Comments</Menu.Item>
                            <Menu.Item key="10" disabled onClick={onMenuDashboardAllLinks}>YouTube</Menu.Item>
                        </SubMenu>

                    </Menu>
                </Sider>
                <Layout className="site-layout" style={{marginLeft: 200}}>
                    <Header className="site-layout-background" style={{padding: 0}}>
                        <div className="logout">
                            <Tooltip title="Logout">
                                <Button
                                    type="primary"
                                    shape="circle"
                                    icon={<LogoutOutlined/>}
                                    onClick={logout}
                                />
                            </Tooltip>
                        </div>
                        <h2 className="headerTitle">LikeStoryEg Management tool | version 2.1</h2>
                    </Header>
                    <Content style={{margin: "0 16px"}}>
                        {showoDashboard && <Dashboard/>}
                        {showSocialPasses && <SocialPasses/>}
                        {showAppRelease && <AppRelease/>}
                        {showoDashboardAllLinks && <AllLinks platform={platform}/>}
                        {showArchivedLinks && <ArchivedLinks/>}
                        {showAds && <Ads/>}
                        {showComments && <Comments/>}
                        {showUsersReferrals && <UsersReferrals />}
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
}

export default Home;
