import React, {useEffect, useState} from "react";
import {
    Layout,
    Spin,
    Typography,
    Tooltip,
    Button,
    message,
    Avatar,
    Row,
    Col,
    Modal,
    Select,
    InputNumber,
    Input,
} from "antd";
import {
    LogoutOutlined,
    ReloadOutlined,
    ArrowLeftOutlined,
    PlusCircleOutlined,
    EditOutlined
} from "@ant-design/icons";
import "./styles.css";
import CommonApis from "../api/CommonApis";
import UserCards from "../cards/UserCard";
import setTitle from "../../utils/Utils";
import UserData from "../tableData/UserData";

export default function UserPosts(props) {
    const {Header, Content, Footer} = Layout;
    const {Title} = Typography;
    const {Option} = Select;

    // cards data
    const [totalLikes, setTotalLikes] = useState(0);
    const [totalPurchases, setTotalPurchases] = useState(0);
    const [totalPosts, setTotalPosts] = useState(0);
    const [userBalance, setUserBalance] = useState(0);
    const [userPosts, setUserPosts] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [userData, setUserData] = useState({});
    const [userNotfound, setUserNotFoud] = useState(false);
    const [postsSizeText, setPostSizeText] = useState(0);
    const [showPostCreationModal, setShowPostCreationModal] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [platform, setPlatform] = useState('')
    const [objective, setObjective] = useState('')
    const [objectiveMax, setObjectiveMax] = useState(1);
    const [showFacebookChoser, setShowFacebookChoser] = useState(false);
    const [showYoutubeChoser, setShowYoutubeChoser] = useState(false);
    const [postLink, setPostLink] = useState('');

    const [showBalanceUpdate, setShowBalanceUpdate] = useState(false)
    const [modalBalanceUpdateLoading, setModalBalanceUpdateLoading] = useState(false)
    useEffect(() => {
        setLoadingData(true);
        console.log(props.match.params.userId);
        setUserId(props.match.params.userId);
        getUserData(props.match.params.userId);
    }, []);

    const getUserData = (userId) => {
        setLoadingData(true);
        CommonApis.getUserData(userId)
            .then((res) => {
                if (res.data === null) {
                    message.error("error getting data at this time!");
                    return;
                }
                setTotalPosts(res.data.totalPosts);
                setTotalLikes(res.data.totalLikes);
                setTotalPurchases(res.data.totalPurchases);
                setUserBalance(res.data.currentBalance);
                setUserPosts(res.data.posts);
                console.log(res.data);
                setUserData(res.data.user);
                setTitle(res.data.user.name + " | Profile");
                setLoadingData(false);
            })
            .catch((err) => {
                if (err.response.status !== null) {
                    if (err.response.status === 404) {
                        // user not found
                        setUserNotFoud(true);
                    }
                }
            });
    };

    const onUserDataRefersh = () => {
        getUserData(userId);
        console.log("called from child")
    };

    const [userId, setUserId] = useState("");

    const logout = () => {
        CommonApis.logout();
        props.history.push("/login");
        message.success("logged out..");
    };

    const addPostCreationModal = () => {
        clearAllfields();
        setShowPostCreationModal(true)
    }

    const handleNewPostOk = () => {
        if (platform === "") {
            message.error("Platform field is required");
            return;
        }
        if (objective === "") {
            message.error("Objective field is required");
            return;
        }
        if (postLink === "") {
            message.error("Link field is required");
            return;
        }

        setModalLoading(true);

        const post = {
            userId,
            link: postLink,
            platform,
            objective,
            maxCount: objectiveMax
        }

        console.log(`post to submit ${JSON.stringify(post)}`)

        CommonApis.addUserPost(post)
            .then((res) => {
                console.log(res.data);
                setModalLoading(false);
                setShowPostCreationModal(false);

                getUserData(userId);
                message.success("Post created successfully");
            })
            .catch((err) => {
                console.log(err);
                setModalLoading(false);
                setShowPostCreationModal(false);

                message.error("Error creating post !");
            });
    }

    const handleCancel = () => {
        clearAllfields();
        setShowPostCreationModal(false)
    }

    function clearAllfields() {
        setPlatform("");
        setObjective("");
        setObjectiveMax(1);
        setPostLink("");
    };

    const handlePostPlatformChange = (event) => {
        setPlatform(event);
        console.log(`selected platform ${event}`)
        if (platform === 'Facebook') {
            setShowFacebookChoser(false);
            setShowYoutubeChoser(true);
        } else {
            setShowFacebookChoser(true);
            setShowYoutubeChoser(false);
        }
    }

    const handlePostObjectiveChange = (event) => {
        setObjective(event);
    }

    const handlePostObjectiveMaxChange = (value) => {
        setObjectiveMax(value);
    }

    const handleLinkChange = (val) => {
        setPostLink(val.target.value)
    }

    const refreshData = () => {
        getUserData(userId);
    }

    const showBalanceUpdateModal = () => {
        setShowBalanceUpdate(true);
    };

    const handleBalanceUpdateModalChange = () => {
        setModalBalanceUpdateLoading(true);
        let update = {
            userId,
            newValue: userBalance
        }
        CommonApis.updateUserPoints(update)
            .then(res => {
                message.success("User points updated successfully.");

                setShowBalanceUpdate(false);
                setModalBalanceUpdateLoading(false)
                window.location.reload();
            })
            .catch(err => {
                console.log(err);
                message.error("Error updating user points !");
                setModalBalanceUpdateLoading(false);
            })

    }

    const generateReferralCode = (userId) => {
        CommonApis.getReferralCode(userId).then(res => {
            userData.referralCode = res.code;
            setUserData(userData);
            refreshData();
        });
    }

    const handleBalanceUpdateCancel = () => {
        setShowBalanceUpdate(false);
    }

    const userPointsValueChange = (value) => {
        setUserBalance(value);
    }
    return (
        <Layout className="layout">
            <Header className="site-layout-background" style={{padding: 0}}>
        <span>
          <a href="/home" className="back">
            {
                <ArrowLeftOutlined
                    style={{fontSize: "32px", color: "#08c"}}
                    className="nav-text"
                />
            }
          </a>
        </span>
                <div className="logout">
                    <Tooltip title="Logout">
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<LogoutOutlined/>}
                            onClick={logout}
                        />
                    </Tooltip>
                </div>
                <h2 className="headerTitle">Back Home</h2>
            </Header>
            <Content style={{padding: "0 50px"}}>
                <Spin spinning={loadingData}>
                    {!userNotfound ? (
                        <div>
                            <br/>
                            <table>
                                <tr>
                                    <td>
                                        <Avatar size={64} src={userData.picture}/>
                                    </td>
                                    <td>
                                        <Title
                                            level={2}
                                            style={{marginLeft: "10px", marginTop: "45px"}}
                                        >
                                            {userData.name}
                                        </Title>
                                        <p style={{marginLeft: "10px"}}>{userData.email}</p>
                                    </td>
                                </tr>
                            </table>

                            <br/>
                            <UserCards
                                totalLikes={totalLikes}
                                currentBalance={userBalance}
                                totalPosts={totalPosts}
                                totalPurchases={totalPurchases}
                                referralCode={userData.referralCode}
                                referralCount={userData.referralCount}
                                onGenerateReferralCode={() => generateReferralCode(userData.uid)}
                            />

                        </div>) : (
                        <div className="centered">
                            <img src={process.env.PUBLIC_URL + "/images/user-not-found.png"}/>
                        </div>
                    )}

                    <br/>
                    <br/>
                    <Row className="post-size">

                    </Row>

                    <Spin spinning={loadingData}>
                        <div className="post-table-container">
                            <Row>
                                <Col>
                                    <Title level={3}>Table of Posts</Title>
                                </Col>

                                <Col>
                                    <Tooltip title="reload table of Social passes">
                                        <Button className="post-refresh-button" shape="circle" icon={<ReloadOutlined/>}
                                                onClick={refreshData}/>
                                    </Tooltip>
                                </Col>
                                <Col span={20}>
                                    <div>
                                        <Tooltip title="Add new Post">
                                            <Button
                                                className="post-add-button"
                                                type="primary"
                                                shape="round"
                                                size="large"
                                                onClick={addPostCreationModal}
                                                icon={<PlusCircleOutlined/>}
                                            />
                                        </Tooltip>
                                        <Tooltip title="Edit user balance">
                                            <Button
                                                className="post-add-button"
                                                type="primary"
                                                shape="round"
                                                size="large"
                                                onClick={showBalanceUpdateModal}
                                                icon={<EditOutlined/>}
                                            />
                                        </Tooltip>
                                    </div>

                                </Col>
                                <Col span={22}>

                                </Col>
                            </Row>
                        </div>
                    </Spin>
                    <UserData data={userPosts} handleParentFun={onUserDataRefersh}/>
                </Spin>

                <Modal
                    title="Add New Post"
                    visible={showPostCreationModal}
                    onOk={handleNewPostOk}
                    confirmLoading={modalLoading}
                    onCancel={handleCancel}
                >

                    <div>
                        <Row>
                            <Col span={10}>
                                <p>Platform:</p>
                                <Select
                                    required
                                    // defaultValue="facebook"
                                    style={{width: "100%"}}
                                    value={platform}
                                    onChange={handlePostPlatformChange}
                                >
                                    <Option value="Facebook">Facebook</Option>
                                    <Option value="YouTube">YouTube</Option>

                                    <Option value="Twitter" disabled>
                                        Twitter
                                    </Option>
                                    <Option value="Instagram" disabled>
                                        Instagram
                                    </Option>

                                </Select>
                            </Col>
                            {showFacebookChoser && <Col span={10} className="modal-right-view">
                                <p>Objective:</p>
                                <Select
                                    //   defaultValue="likes"
                                    style={{width: "100%"}}
                                    onChange={handlePostObjectiveChange}
                                    value={objective}
                                >
                                    <Option value="Like">Like</Option>
                                    <Option value="Love">Love</Option>
                                    <Option value="Haha">Haha</Option>
                                    <Option value="wow">Wow</Option>
                                    <Option value="Sad">Sad</Option>
                                    <Option value="Angry">Angry</Option>
                                    <Option value="friendRequest">Add Friend</Option>
                                    <Option value="followRequest">Follow</Option>
                                    <Option value="pageLike">Like Page</Option>
                                    <Option value="groupJoin">Join Group</Option>
                                </Select>
                            </Col>}
                            {showYoutubeChoser && <Col span={10} className="modal-right-view">
                                <p>Objective:</p>
                                <Select
                                    //   defaultValue="likes"
                                    style={{width: "100%"}}
                                    onChange={handlePostObjectiveChange}
                                    value={objective}
                                >
                                    <Option value="YoutubeLike">Like Video</Option>
                                    <Option value="YoutubeSubscribe">Subscribe to channel</Option>
                                </Select>
                            </Col>}
                        </Row>

                        <br/>
                        <p>Objective Max: (ex: 1000 Likes)</p>
                        <InputNumber
                            min={1}
                            value={objectiveMax}
                            defaultValue={1}
                            onChange={handlePostObjectiveMaxChange}
                        />

                        <br/>
                        <br/>
                        <p>Link:</p>
                        <Input placeholder="Paste link here..." onChange={handleLinkChange}/>
                    </div>

                </Modal>


                <Modal
                    title="Update User Points"
                    visible={showBalanceUpdate}
                    onOk={handleBalanceUpdateModalChange}
                    confirmLoading={modalBalanceUpdateLoading}
                    onCancel={handleBalanceUpdateCancel}
                >
                    <p>New user points value: (ex: 100)</p>
                    <InputNumber
                        min={0}
                        value={userBalance}
                        defaultValue={0}
                        onChange={userPointsValueChange}
                    />
                </Modal>

            </Content>
        </Layout>
    );
}
