import React, {useEffect, useState} from "react";
import {Col, Row} from "antd";
import StatisticsCard from "../cards/statistics-card";
import UsersReferralsData from "../tableData/UsersReferralsData";
import CommonApis from "../api/CommonApis";
import Icon, {UsergroupAddOutlined, RiseOutlined, LineChartOutlined, GiftOutlined} from "@ant-design/icons";

export default function UsersReferrals() {
    const [data, setData] = useState([]);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);
    const [totalCount, setTotalCount] = useState(0);
    const [usersWithReferralCodes, setUsersWithReferralCodes] = useState(0);
    const [totalReferralOperationsCount, setTotalReferralOperationsCount] = useState(0);
    const cardsValues = [{
        title: "Total Users With Referral Codes",
        value: usersWithReferralCodes,
        type: "primary",
        icon: <UsergroupAddOutlined />
    },
        {
            title: "Total Referral Operations Count",
            value: totalReferralOperationsCount,
            type: "primary",
            icon:  <LineChartOutlined />
        },
        {
            title: "Total Point Reward Count",
            value: totalReferralOperationsCount * 200,
            type: "success",
            icon:  <GiftOutlined />
        }
    ];

    useEffect(() => {
        loadUsers(1, 10);
    }, []);

    const loadUsers = (pageIndex, pageSize) => {
        setPageIndex(pageIndex);
        setPageSize(pageSize);
        CommonApis.getReferralUsersDashboard(pageIndex, pageSize).then(res => {
            setData(res.data.users);
            setTotalCount(res.data.totalElements);
            setUsersWithReferralCodes(res.data.usersWithReferralCodes);
            setTotalReferralOperationsCount(res.data.totalReferralOperationsCount);
        }).catch(err => {
            console.log(err);
        })
    }


    return (
        <Row gutter={[24, 24]} style={{padding: "30px"}}>
            <Col span={24}>
                <StatisticsCard data={cardsValues}/>
            </Col>
            <Col span={24}>
                <UsersReferralsData
                    data={data}
                    pageSize={pageSize}
                    pageIndex={pageIndex}
                    totalElements={totalCount}
                    onChange={loadUsers}
                />
            </Col>
        </Row>
    )
}