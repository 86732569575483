import React, { useState, useEffect } from "react";
import {
  Table,
  Input,
  Button,
  Avatar,
  Modal,
  InputNumber,
  message,
  Select,
  Pagination,
} from "antd";
import Highlighter from "react-highlight-words";
import { SearchOutlined, StopOutlined } from "@ant-design/icons";
import CommonApis from "../api/CommonApis";

export default function UsersData(props) {
  // setHasData(true)
  /*
  const data = 
*/
  const [dataset, setDataset] = useState([
    {
      userId: "",
      email: "",
      name: "",
      picture: "",
      customer: "",
      totalPurchases: "",
      userPoints: "",
      totalActivePosts: "",
      totalInactivePosts: "",
    },
  ]);
  const update = {
    userId: "",
    newValue: "",
  };

  const { Option } = Select;

  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [userPoints, setUserPoints] = useState(0);
  const [currentRowIndex, setCurrentRowIndex] = useState();
  const [currentRecord, setCurrentRecord] = useState({});
  const [blockModalVisible, setBlockModalVisible] = useState(false);
  const [blockModalLoading, setBlockModalLoading] = useState(false);
  const [userId, setUserId] = useState();
  const [blockStatus, setBlockStatus] = useState("unblock");
  const [tempBlockPeriod, setTempBlockPeriod] = useState(24);
  const [blockCause, setBlockCause] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  let pageSizeChanged = false;

  let searchInput;

  useEffect(() => {
    setDataset(props.data);
    setPageSize(props.pageSize);
    setTotal(props.totalUsers);
    setPageNumber(props.pageNumber);
  }, [props.data, dataset]);

  const handleCancel = () => {
    console.log("Clicked cancel button");
    setModalVisible(false);
  };

  function userPointsValueChange(value) {
    setUserPoints(value);
    console.log(`new values: ${value}`);
  }

  const showModal = (record, userPoints, rowIndex) => {
    setCurrentRecord(record);
    setCurrentRowIndex(rowIndex);
    console.log(userPoints);
    setModalVisible(true);
    setUserPoints(userPoints);
  };

  const showBlockUserModal = (record) => {
    console.log("creating modal : " + JSON.stringify(record));
    setUserId(record.userId);
    setBlockModalVisible(true);
  };

  const handleModalChange = () => {
    setModalLoading(true);
    // console.log(currentRecord)

    update.userId = currentRecord.userId;
    console.log(currentRecord.userId);
    update.newValue = userPoints;
    let thisProps = { ...props };
    CommonApis.updateUserPoints(update)
      .then((res) => {
        message.success("User points updated successfully.");
        console.log(res);
        dataset[currentRowIndex] = res.data.userPoints;
        setDataset(dataset);
        thisProps.handleParentFun();

        setModalLoading(false);
        handleCancel();
      })
      .catch((err) => {
        console.log(err);
        message.error("Error updating user points !");
        setModalLoading(false);
      });
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select());
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text.toString()}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const blockValueChange = (value) => {
    setBlockStatus(value);
  };

  const tempBlockValueChange = (value) => {
    parseInt(value, 24);
    setTempBlockPeriod(value);
  };

  const handleExplanationChange = (value) => {
    setBlockCause(value.target.value);
  };

  const onPageChanged = (pageNumber, pageSize) => {
    console.log("OnPageChanged")
    setPageSize(pageSize);
    console.log("pageSizeChanged", pageSizeChanged);
    if(pageSizeChanged)
    {
      console.log("pageSizeChanged!")
      pageNumber = 1;
      pageSizeChanged = false;
    }

    props.handleParentFun(pageSize, pageNumber);

    // CommonApis.getDashboard(pageSize, pageNumber).then((res) => {
    //   console.log(res.data);
    //   setDataset(res.data.users.data);
    //   setTotal(res.data.totalUser);
    // });
  };
  const onShowSizeChange = (pageNumber, pageSize) => {
    console.log("OnShowSizeChanged");
    console.log(pageNumber, pageSize);
    setPageSize(pageSize)
    setPageNumber(1);
    pageSizeChanged = true;
    console.log("pageSizeChanged", pageSizeChanged);
    // CommonApis.getDashboard(pageSize, pageNumber).then((res) => {
    //   console.log(res.data);
    //   setDataset(res.data.users.data);
    //   setTotal(res.data.totalUser);
    // });
  };

  const handleBlockModalOk = () => {
    setBlockModalLoading(true);
    let blockUser = {
      userId: userId,
      status: blockStatus,
      unblockTime: tempBlockPeriod,
      lockoutCause: blockCause,
    };

    console.log(`sending block user : ${JSON.stringify(blockUser)}`);

    CommonApis.blockUser(blockUser)
      .then((res) => {
        if (res.data.message) {
          message.success("user blocked successfully !");
          setBlockModalVisible(false);
          setBlockModalLoading(false);
        } else {
          message.error("can't block user !");
          setBlockModalLoading(false);
        }
      })
      .catch((err) => {
        message.error("user blocked successfully !");
        setBlockModalLoading(false);
      });
  };

  const handleBlockModalCancel = () => {
    setBlockModalVisible(false);
  };
  const columns = [
    {
      title: "",
      dataIndex: "picture",
      key: "pic",
      width: "2%",
      render: (theImageURL) => <Avatar src={theImageURL} />,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "userId",
      ...getColumnSearchProps("email"),
      render: (email, row) => (
        <a href={`/user/details/${row.userId}/`}>{email}</a>
      ),
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Total Points",
      dataIndex: "userPoints",
      key: "totalpoints",
      render: (userPoints) =>
        userPoints > 0 ? (
          <span style={{ color: "green" }}>{userPoints}</span>
        ) : (
          <span style={{ color: "green" }}>{userPoints}</span>
        ),
    },
    {
      title: "Total Purchases",
      dataIndex: "totalPurchases",
      key: "totalpurchases",
    },
    {
      title: "Total Active Posts",
      dataIndex: "totalActivePosts",
      key: "totalactiveposts",
    },
    {
      title: "Total Inactive Posts",
      dataIndex: "totalInactivePosts",
      key: "totalinactiveposts",
    },
    {
      title: "Actions",
      key: "operation",
      render: (record, rowIndex) => (
        <div>
          <Button
            type="dashed"
            onClick={() => showModal(record, record.userPoints, rowIndex)}
          >
            Update Points
          </Button>

          <Button
            type="text"
            icon={<StopOutlined />}
            danger
            onClick={() => showBlockUserModal(record)}
          >
            Block
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        pagination={{
          showSizeChanger: true,
          onChange: onPageChanged,
          onShowSizeChange: onShowSizeChange,
          pageSize: pageSize,
          pageSizeOptions: [1,5,10,20,50,100],
          total: total,
          current: pageNumber
        }}
        dataSource={dataset}
        rowKey="userId"
      />

      <Modal
        title="Update User Points"
        visible={modalVisible}
        onOk={handleModalChange}
        confirmLoading={modalLoading}
        onCancel={handleCancel}
      >
        <p>New user points value: (ex: 100)</p>
        <InputNumber
          min={0}
          value={userPoints}
          defaultValue={0}
          onChange={userPointsValueChange}
        />
      </Modal>

      <Modal
        title="Block User"
        visible={blockModalVisible}
        onOk={handleBlockModalOk}
        confirmLoading={blockModalLoading}
        onCancel={handleBlockModalCancel}
      >
        <Select
          defaultValue="unblock"
          style={{ width: 220 }}
          value={blockStatus}
          onChange={blockValueChange}
        >
          <Option value="unblock">Unblock</Option>
          <Option value="block">Block</Option>
          <Option value="tempblock">Temporary Block</Option>
        </Select>
        <br />
        <br />

        <p>Temporary Block period: </p>
        <Select
          defaultValue="24"
          style={{ width: 220 }}
          value={tempBlockPeriod}
          onChange={tempBlockValueChange}
        >
          <Option value="24">24H</Option>
          <Option value="48">48H</Option>
          <Option value="168">1 Week</Option>
          <Option value="336">2 Week</Option>
          <Option value="730">1 Month</Option>
        </Select>

        <br />
        <br />
        <Input
          placeholder="Write block explanation here..."
          onChange={handleExplanationChange}
        />
      </Modal>
    </div>
  );
}
