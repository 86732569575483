
import React, { useState, useEffect } from 'react'
import {
    Typography,
    Button,
    Tooltip,
    Row,
    Col,
    Select,
    message,
    Input,
    Card,
    Spin
  } from "antd";
  import {
    CheckOutlined
  } from "@ant-design/icons";
  import CommonApis from "../../../api/CommonApis";
  import CommentsData from '../../../tableData/CommentsData'

import "./styles.css"
export default function Comments () {
    const { Title, Text } = Typography;
    const {Option} = Select;

    const [showCommentLoadingSpin, setCommentLoadingSpin] = useState(false);
    const [showCommentCategLoadingSpin, setCommentCategLoadingSpin] = useState(false);

    const [commentCategoryValue, setCommentCategoyValue] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [commentValue, setCommentValue] = useState('');

    const [allCategories, setAllCategories] = useState([])
    const [allComments, setAllComments] = useState([])

    useEffect(() => {
        loadData();
      }, []);


    const loadData = () => {

        setCommentCategLoadingSpin(true);
        setCommentLoadingSpin(true);

        CommonApis.getAllCommentsCategories()
            .then(res => {
                if (res.data) {
                    console.log(res.data)
                    setAllCategories(res.data);
                    setCommentCategLoadingSpin(false)
                }
            })
            .catch(err => {
                console.log(err)
            });

        CommonApis.getAllComments()
            .then(res => {
                if (res.data) {
                    console.log(res)
                    setAllComments(res.data)
                    setCommentLoadingSpin(false);
                }
                
            })  
            .catch(err => {

            })  

       
    }
    const commentCategoryValuechange = (event) => {
        setCommentCategoyValue(event.target.value)
    }

    const commentValuechange = (event) => {
        setCommentValue(event.target.value)
    }

    const addNewCommentCategory = () => {
        setCommentCategLoadingSpin(true);
        const category = {
            category: commentCategoryValue
        };

        CommonApis.addNewCommentCategory(category)
            .then(res => {
                if (res.data === null) {
                    console.log("error loading data!");
                }
                console.log(res.data);
                setCommentCategLoadingSpin(false);
                message.success('Comment Category saved successfully !')
                loadData();
                setCommentCategoyValue('')
            })
            .catch(err => {
                console.log(err);
                setCommentCategLoadingSpin(false);
                message.error('Error adding new Comment Category')
            })
    }

    const addNewComment = () => {
        setCommentCategLoadingSpin(true);

        const comment = {
            comment: commentValue,
            category: selectedCategory
        };

        console.log(comment);

        CommonApis.addNewComment(comment)
            .then(res => {
                if (res.data === null) {
                    console.log("error loading data!");
                }
                console.log(res.data);
                setCommentCategLoadingSpin(false);
                message.success('Comment saved successfully !')
                loadData();
                setCommentValue('')
            })
            .catch(err => {
                console.log(err);
                setCommentCategLoadingSpin(false);
                message.error('Error adding new Comment')
            })
    }

    const onCategoryListChange = (val) => {
        setSelectedCategory(val);
    }
    return (
        <div>
            <Title>Manage Comments</Title>

            <br/>
            <br/>
            <section className="container">
                <div className="left">
                <div className="site-card-border-less-wrapper" style={{ width: '100%' }}>
                    <Card title="1. Add New Category" bordered={true} className="card">
                        <Spin spinning={showCommentCategLoadingSpin}>
                            <Input placeholder="Write category name here..." value={commentCategoryValue} onChange={commentCategoryValuechange}/>
                            <br/>
                            <br/>
                            <div className="new-cat-btn">
                                <Button type="primary" icon={<CheckOutlined />} size='large' onClick={addNewCommentCategory}>
                                    Save Category
                                </Button>
                            </div>
                        </Spin>
                    </Card>
                
                </div>
                </div>

                <div className="right">
                <div className="site-card-border-less-wrapper" style={{ width: '100%' }}>
                    <Card title="2. Add New Comment" bordered={true} className="card">
                        <Spin spinning={showCommentLoadingSpin}>
                            <Row className="ads-size">
                            <Col span={12}>
                                <Input placeholder="Write comment here..." value={commentValue} onChange={commentValuechange} />
                            </Col>

                            <Col span={12}>
                            <Select placeholder="Select Category" 
                                value={selectedCategory} 
                                style={{ marginLeft: '10px', width: '100%' }} 
                                onChange={onCategoryListChange}
                                options={allCategories.map(c => ({value: c.category, label: c.category}))}/>
                                    
                            
                            </Col>
                            </Row>
                            <br/>
                            <div className="new-cat-btn">
                                <Button type="primary" icon={<CheckOutlined />} size='large' onClick={addNewComment}>
                                    Save Comment
                                </Button>
                            </div>
                        </Spin>
                    </Card>
                </div>
                </div>
            </section>
            
            <br/>
            <CommentsData data={allComments}></CommentsData>
        </div>
    )

}