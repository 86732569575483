import React from "react"
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import "./App.css"
import Login from "./components/login/index"
import Error404 from "./components/errors/ErrorPage"
import AuthenticatedRoute from './components/router/AuthenticatedRoute'
import Home from './components/home/index'
import UserPosts from "./components/posts"

function App() {
    return (
        <Router>
            <>
                <Switch>
                    <Route path="/" exact component={Login}/>
                    <Route path="/login" component={Login}/>
                    <AuthenticatedRoute path="/home" component={Home}/>
                    <AuthenticatedRoute path="/user/details/:userId" component={UserPosts}/>
                    <Route component={Error404}/>
                </Switch>
            </>
        </Router>
    );
}

export default App;
