import React, { useState, useEffect } from "react";
import {
  Spin,
  Typography,
  Tooltip,
  Button,
  Row,
  Col,
  Modal,
  Radio,
  Input,
  message,
  Space,
  InputNumber,
  Popconfirm,
} from "antd";
import {
  AimOutlined,
  DeleteOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";

import Cards from "../cards/Cards";
import UsersData from "../tableData/UsersData";
import CommonApis from "../api/CommonApis";
import "./styles.css";

export default function Dashboard() {
  const { Title } = Typography;
  const [dashboardData, setDashboardData] = useState([]);

  // cards data
  const [totalLikes, setTotalLikes] = useState(0);
  const [totalPurchases, setTotalPurchases] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loadingData, setLoadingData] = useState(true);
  const [showDirectModal, setShowDirectModal] = useState(false);
  const [showUnblockUsers, setShowUnblockUsers] = useState(false);
  const [directModalLoading, setDirectModalLoading] = useState(false);
  const [profileValue, setProfileValue] = useState("");
  const [groupValue, setGroupValue] = useState("email");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [points, setPoints] = useState(0);
  const [filterModelVisible, setFilterModelVisible] = useState(false);
  const [popConfimVisible, setPopConfimVisible] = useState(false);
  const [popConfirmLoading, setPopConfirmLoading] = useState(false);
  useEffect(() => {
    loadDashboard(pageSize, pageNumber);
  }, []);

  const loadDashboard = (pageSize, pageNumber) => {
    setLoadingData(true);
    CommonApis.getDashboard(pageSize, pageNumber, points)
      .then((res) => {
        console.log("res", res);
        if (res.data === null) {
          console.log("error loading data!");
        }
        setTotalLikes(res.data.totalLikes);
        setTotalPurchases(res.data.totalPurchases);
        setTotalUsers(res.data.totalUser);
        const users = res.data.users.data
          ? res.data.users.data
          : res.data.users;
        setPageNumber(pageNumber);
        setPageSize(pageSize);
        setDashboardData(users);
        console.log("dashboardData", dashboardData);
        setLoadingData(false);
      })
      .catch((err) => {
        console.log("error: " + err.response);
        setLoadingData(false);
      });
  };

  const handleDirectUserLoader = () => {
    setShowDirectModal(true);
  };

  const unblockAllUsers = () => {
    setShowUnblockUsers(true);
  };

  const handleUnblockUsersOk = () => {
    CommonApis.unblockAllUsers()
      .then((res) => {
        console.log(res.data);
        message.success("All users unblocked successfully!");
        setShowUnblockUsers(false);
      })
      .catch((err) => {
        console.log(err);
        message.error("Unexpected error happened.");
      });
  };

  const handleDirectLoaderOk = () => {
    if (profileValue === "") {
      message.error("Email or ID required");
    }

    if (groupValue === "email") {
      CommonApis.getUserId(profileValue)
        .then((res) => {
          console.log(res.data);
          setDirectModalLoading(false);
          setShowDirectModal(false);

          window.open(`/user/details/${res.data.userId}/`, "_blank").focus();
        })
        .catch((err) => {
          console.log(err);
          setDirectModalLoading(false);
          //    setShowPostCreationModal(false);

          message.error("User not found !");
        });
    }
    if (groupValue === "userId") {
      window.open(`/user/details/${profileValue}/`, "_blank").focus();
    }
  };

  const handleDirectCancel = () => {
    setShowDirectModal(false);
  };

  const handleUnblockUsersCancel = () => {
    setShowUnblockUsers(false);
  };

  const onGroupChange = (val) => {
    setGroupValue(val.target.value);
  };

  const handleValueChange = (val) => {
    setProfileValue(val.target.value);
  };

  const handleFilterUsersOk = () => {
    loadDashboard(pageSize, pageNumber);
    setFilterModelVisible(false);
  };

  const handleFilterUsersCancel = () => {
    setPoints(0);
    setFilterModelVisible(false);
    loadDashboard(pageSize, pageNumber);
  };

  const handleClearReactedPosts = () => {
    setPopConfirmLoading(true);
    CommonApis.clearReactedPosts()
      .then((res) => {
        message.success("Reacted posts cleared successfully!");
      })
      .catch((err) => {
        console.log(err);
        message.error("Unexpected error happened");
      })
      .finally(() => {
        setPopConfimVisible(false);
        setPopConfirmLoading(false);
      });
  };

  return (
    <div>
      <Title>Overview &#38; Users</Title>
      <div
        className="site-layout-background"
        style={{ padding: 24, textAlign: "center" }}
      >
        <Cards
          totalUsers={totalUsers}
          totalPurchases={totalPurchases}
          totalLikes={totalLikes}
        />
      </div>

      <Row className="social-pass-size">
        <Col span={12}>
          <p>
            Table of users: &#160;
            <Tooltip title="click the Search icon to search for users">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          </p>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Space>
            <Tooltip title="Unblock All Users">
              <Button
                className="social-apps-add-button"
                type="primary"
                shape="round"
                size="middle"
                onClick={unblockAllUsers}
                icon={<UserSwitchOutlined />}
              />
            </Tooltip>
            <Tooltip title="Direct Profile Loader">
              <Button
                className="social-apps-add-button"
                type="primary"
                shape="round"
                size="middle"
                onClick={handleDirectUserLoader}
                icon={<AimOutlined />}
              />
            </Tooltip>
            <Tooltip title="Filter Users With Points">
              <Button
                className="social-apps-add-button"
                type="primary"
                shape="round"
                size="middle"
                onClick={() => setFilterModelVisible(true)}
                icon={<FilterOutlined />}
              />
            </Tooltip>
            <Tooltip title="Filter Users With Points">
              <Popconfirm
                title="Clear Reacted Posts?"
                visible={popConfimVisible}
                onConfirm={handleClearReactedPosts}
                okButtonProps={{ loading: popConfirmLoading }}
                onCancel={() => setPopConfimVisible(false)}
              >
                <Button
                  className="social-apps-add-button"
                  type="primary"
                  shape="round"
                  size="middle"
                  onClick={() => setPopConfimVisible(true)}
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>
            </Tooltip>
          </Space>
        </Col>
      </Row>

      <Spin spinning={loadingData}>
        <div className="data-table">
          {!loadingData && (
            <UsersData
              data={dashboardData}
              totalUsers={totalUsers}
              pageSize={pageSize}
              pageNumber={pageNumber}
              handleParentFun={loadDashboard}
            />
          )}
        </div>
      </Spin>

      <Modal
        title="Direct Profile Loader"
        visible={showDirectModal}
        onOk={handleDirectLoaderOk}
        confirmLoading={directModalLoading}
        onCancel={handleDirectCancel}
      >
        <div>
          <Radio.Group
            defaultValue="email"
            size="large"
            onChange={onGroupChange}
          >
            <Radio.Button value="email">Email</Radio.Button>
            <Radio.Button value="userId">User ID</Radio.Button>
          </Radio.Group>

          <br />
          <br />
          <p>Email or User ID:</p>
          <Input
            placeholder="Paste email or ID here..."
            onChange={handleValueChange}
          />
        </div>
      </Modal>

      <Modal
        title="Unblock All Users"
        visible={showUnblockUsers}
        onOk={handleUnblockUsersOk}
        confirmLoading={directModalLoading}
        onCancel={handleUnblockUsersCancel}
      >
        <div>
          <h3>Are you sure you want to unblock all users.</h3>
        </div>
      </Modal>

      <Modal
        title="Filter users with points"
        visible={filterModelVisible}
        onOk={handleFilterUsersOk}
        confirmLoading={directModalLoading}
        onCancel={handleFilterUsersCancel}
      >
        <div>
          <Space>
            <h3>Points:</h3>
            <InputNumber
              onChange={(val) => setPoints(val)}
              value={points}
              min={0}
            />
          </Space>
        </div>
      </Modal>
    </div>
  );
}
