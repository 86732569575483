import React from 'react'
import {Button, Card, Col, Row} from "antd";
import {
    DatabaseOutlined,
    DollarCircleOutlined,
    LikeOutlined,
    DashboardOutlined, ShareAltOutlined,
} from "@ant-design/icons";
import Paragraph from "antd/es/typography/Paragraph";

export default function UserCards(props) {

    return (
        <div className="site-card-wrapper">
            <Row gutter={[24, 24]} className="card-row">
                <Col span={4}>
                    <Card
                        className="cards"
                        title="Total Posts"
                        bordered={false}
                        style={{width: 200, height: 200, textAlign: 'center'}}
                    >
                        <DatabaseOutlined style={{fontSize: "50px"}}/>
                        <p className="card-result">
                            <b>{props.totalPosts}</b>
                        </p>
                    </Card>
                </Col>
                <Col span={4}>
                    <Card
                        className="cards"
                        title="Current Balance"
                        bordered={false}
                        style={{width: 200, height: 200, textAlign: 'center'}}
                    >
                        <DashboardOutlined style={{fontSize: "50px"}}/>
                        <p className="card-result">
                            <b>{props.currentBalance}</b>

                        </p>

                    </Card>
                </Col>
                <Col span={4}>
                    <Card
                        className="cards"
                        title="Total Purchases"
                        bordered={false}
                        style={{width: 200, height: 200, textAlign: 'center'}}
                    >
                        <DollarCircleOutlined style={{fontSize: "50px"}}/>
                        <p className="card-result">
                            <b>${props.totalPurchases}</b>
                        </p>
                    </Card>
                </Col>
                <Col span={4}>
                    <Card
                        className="cards"
                        title="Total Likes"
                        bordered={false}
                        style={{width: 200, height: 200, textAlign: 'center'}}
                    >
                        <LikeOutlined style={{fontSize: "50px"}}/>
                        <p className="card-result">
                            <b>{props.totalLikes}</b>
                        </p>
                    </Card>
                </Col>
                <Col span={4}>
                    <Card
                        className="cards"
                        title="Referral Code"
                        bordered={false}
                        style={{width: 200, height: 200, textAlign: 'center'}}
                    >
                        <ShareAltOutlined style={{fontSize: "50px"}}/>
                        <p className="card-result">
                            {props.referralCode &&
                            <Paragraph copyable
                                       style={{fontWeight: "bold", color: '#0ea4df'}}
                            >{props.referralCode}</Paragraph>}
                            {!props.referralCode &&
                                <Button type={"dashed"} onClick={props.onGenerateReferralCode}>Generate</Button>
                            }
                        </p>
                    </Card>
                </Col>
                <Col span={4}>
                    <Card
                        className="cards"
                        title="Referral Count"
                        bordered={false}
                        style={{width: 200, height: 200, textAlign: 'center'}}
                    >
                        <LikeOutlined style={{fontSize: "40px"}}/>
                        <p className="card-result">
                            <b>{props.referralCount}</b>
                        </p>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
  