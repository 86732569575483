import React from "react";
import {Avatar, Table} from "antd";
import Paragraph from "antd/es/typography/Paragraph";

export default function UsersReferralsData({data, pageSize, pageIndex, onChange, totalElements}) {

    const columns = [{
        title: "",
        dataIndex: "picture",
        key: "pic",
        render: (theImageURL) => <Avatar src={theImageURL}/>,
    },
        {
            title: "Email",
            dataIndex: "email",
            key: "userId",
            render: (email, row) => (
                <a href={`/user/details/${row.uid}/`}>{email}</a>
            ),
        },
        {
            title: "Full Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Referral Code",
            dataIndex: "referralCode",
            key: "referralCode",
            render: val =>
                <>
                    <Paragraph copyable>{val}</Paragraph>
                </>
        },
        {
            title: "Referral Count",
            dataIndex: "referralCount",
            key: "referralCount",
        }];
    return (
        <Table columns={columns}
               dataSource={data}
               pagination={{
                   showSizeChanger: true,
                   onChange: onChange,
                   onShowSizeChange: onChange,
                   pageSize: pageSize,
                   pageSizeOptions: [1,5,10,20,50,100],
                  total: totalElements,
               }}

               rowKey="uid"
        />
    )
}