import React from "react";
import {Card, Col, Row, Statistic} from "antd";

export default function StatisticsCard({data}) {

    const typesColor = {
        primary: "#1890ff",
        danger: "#ff4d4f",
        success: "#52c41a",
        warning: "#faad14"
    }
    return (
        <Row gutter={[24,24]}>
            {data.map(itm =>
                <Col span={5}>
                    <Card type={itm.type | "primary"}>
                        <Statistic
                            title={itm.title}
                            value={itm.value}
                            valueStyle={{color: typesColor[itm.type]}}
                            prefix={itm.icon}
                        />
                    </Card>
                </Col>
            )}
        </Row>

    )
}