import React, { useState, useEffect } from "react";
import {
  Badge,
  Avatar,
  Row,
  Col,
  Table,
  Modal,
  Popconfirm,
  Select,
  Switch,
  Typography,
  message,
  Input,
  dataset
} from "antd";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import CommonApis from "../api/CommonApis";

export default function CommentsData(props) {

    
  const { Option } = Select;
  const { Title } = Typography;
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [platform, setPlatform] = useState('');
  const [packageName, setPackageName] = useState('');
  const [adBanner, setAdBanner] = useState('');
  const [adInterstitial, setAdInterstitial] = useState('');
  const [adNative, setAdNative] = useState('');
  const [adPaused, setPausedAd] = useState(false);
  const [dataset, setDataset] = useState([]);
  const [adId, setAdId] = useState(0);

  useEffect(() => {
    setDataset(props.data);
    console.log("received data: " + dataset);
  }, [props.data, dataset]);


  
  const buildUpdateModal = () => {

  }
  

  const columns = [
    {
      title: "comment",
      dataIndex: "comment",
      key: "comment"
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      
    },
    
    {
        title: "Actions",
        key: "operation",
        
    },
  ];

  return (
    <div>
      <Table columns={columns} dataSource={props.data} rowKey="id" />
      
    </div>
  );
}
